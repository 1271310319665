<template>
  <div id="app" :key="Key">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
   return {
     Key: ''
   };
},
  watch: {
   $route: function(newUrl, oldUrl) {
     this.Key = new Date().getTime();
   }
}
}
</script>
<style>
#app {
  color: #1a1a1a;
  background: #f8f8f8;
}
</style>
