import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


Vue.config.productionTip = false

import ViewUI from 'view-design';
import { Message } from "view-design";
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);
// import 'swiper/swiper-bundle.min.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

// reset.css
import './styles/reset.css'

// 公共.css
import './styles/style.css'

// iconfont
import './styles/iconfont.css';
// 混入
import mixin from './mixin'
Vue.mixin(mixin)

// 全局过滤器
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 防抖处理 -- 最后执行
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let timer
  let newFunc = func
  if (event === 'click') {
    newFunc = function () {
      clearTimeout(timer)
      timer = setTimeout(function () {
        func.apply(this, arguments)
      }, 500)
    }
  }
  on.call(this, event, newFunc)
}
// axios 引入
// import axios from 'axios';
// Vue.prototype.$axios = axios
import axios from '@/util/http'
Vue.prototype.$axios = axios
import {instance} from  '@/util/http';
Vue.prototype.$instance = instance
// axios.defaults.baseURL = "http://101.132.156.127:9966/api/"
// axios.defaults.baseURL = "http://101.132.156.127:9965/api/"//测试
// axios.defaults.baseURL = "http://39.99.135.77:9966/api/"//生产
// axios.defaults.baseURL = "http://106.14.174.133:9411/"//生产
// axios.defaults.baseURL = "/"//生产
axios.defaults.baseURL = "http://gw.mubaiwang.com/api/"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
