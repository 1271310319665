import axios from 'axios'


// 第一个代理基础路径配置
axios.defaults.timeout = 50000;
// http://106.14.174.133:9411
axios.defaults.baseURL = '/';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.interceptors.request.use(
    function (config) {
        console.log("config",config);
      // 在发送请求之前做些什么
      config.headers['Auth-Token'] = sessionStorage.getItem('token') || ''
      return config
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error)
  
    })
  
  // 给axios配置响应拦截器
  axios.interceptors.response.use(
    function (response) {
      // 通过响应拦截器，直接返回 res.data
      if (response.data.resCode == 401) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('userInfo')
        window.location.reload()
          this.$Message.error('请登录');//token失效
          this.$router.push('./login')
      }
      // console.log(response.data);
      if (response.data.resCode != '0000') {
        response.data.resDesc && this.$Message.error(response.data.resDesc);
      }
      return (response.data.resDesc ? response.data : response)
    },
    function (error) {
      // 对响应错误做点什么
      if (error.toString().indexOf('401') != -1) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('userInfo')
          this.$Message.error('请登录');//token失效
          this.$router.push('./login')
        return false
      }
      // 对响应错误做点什么
        this.$Message.error('服务器异常请稍后重试');
      // return Promise.reject(error.data.error.message);
      return Promise.reject(error)
    }
  )
// 第二个代理基础路径配置
export const instance = axios.create({
    baseURL:'/',
    timeout: 10000,
    // headers: {
    //   'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8"
    // }

});
instance.interceptors.request.use(
    function (config) {
      // 在发送请求之前做些什么
      config.headers['Auth-Token'] = sessionStorage.getItem('token') || ''
      return config
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error)
  
    })
  
  // 给axios配置响应拦截器
  instance.interceptors.response.use(
    function (response) {
      // 通过响应拦截器，直接返回 res.data
      if (response.data.resCode == 401) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('userInfo')
        window.location.reload()
          this.$Message.error('请登录');//token失效
          this.$router.push('./login')
      }
      // console.log(response.data);
      if (response.data.resCode != '0000') {
        response.data.resDesc && Message.error(response.data.resDesc);
      }
      return (response.data.resDesc ? response.data : response)
    },
    function (error) {
      // 对响应错误做点什么
      if (error.toString().indexOf('401') != -1) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('userInfo')
        this.$Message.error('请登录');//token失效
          this.$router.push('./login')
        return false
      }
      // 对响应错误做点什么
        this.$Message.error('服务器异常请稍后重试');
      // return Promise.reject(error.data.error.message);
      return Promise.reject(error)
    }
  )

export default axios;