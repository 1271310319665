/*
 * @Author: your name
 * @Date: 2020-02-16 01:49:59
 * @LastEditTime: 2020-02-16 01:49:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \ywk\src\filters\index.js
 */

import {
    formatDateTime as dateFormat,
  } from "../util";
  /**
   * 日期格式化 yyyy-MM-dd
   * @param date
   * @returns {*}
   */
  export function formatDate(date, pattern ="yyyy-MM-dd HH:mm:ss") {
    return dateFormat(date,pattern)
  }
