import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: resolve => require(['../views/Home.vue'], resolve),
    meta: {
      parent: "首页",
      name: "Home"
    },
    children: [
      {
        path: '/',
        name: '首页',
        component: resolve => require(['@/views/pages/Index/Index.vue'], resolve),
        meta: {
          parent: "首页",
        },
        children: [
          {
            path: '/about',
            name: '关于韬儒',
            component: resolve => require(['@/views/pages/Index/about.vue'], resolve),
            meta: {
              parent: "首页",
            },
            // children: [
            //   {
            //     path: '/cyText',
            //     name: '韬儒产业详情',
            //     component: resolve => require(['@/views/pages/Index/cyText.vue'], resolve),
            //     meta: {
            //       parent: "关于韬儒",
            //     },
            //   },
            // ]
          },
          {
            path: '/rankingList',
            name: '排行榜',
            component: resolve => require(['@/views/pages/Index/rankingList.vue'], resolve),
            meta: {
              parent: "",
            },
          },
          {
            path: '/goldStoreO',
            name: '金牌店铺',
            component: resolve => require(['@/views/pages/Index/goldStore.vue'], resolve),
            meta: {
              parent: "在线商城",
            },
          },
          {
            path: '/search',
            name: '搜索',
            component: resolve => require(['@/views/pages/Index/search.vue'], resolve),
            meta: {
              parent: "首页",
            },
          },
          {
            path: '/cemeteryNews',
            name: '陵园新闻',
            component: resolve => require(['@/views/pages/Index/cemeteryNews.vue'], resolve),
            meta: {
              parent: "首页",
            },
            children: [
              {
                path: '/text',
                name: '陵园新闻详情',
                component: resolve => require(['@/views/pages/Index/text.vue'], resolve),
                meta: {
                  parent: "陵园新闻",
                },
              },
            ]
          },
        ]
      },
      {
        path: '/activitie',
        name: '公益活动',
        component: resolve => require(['@/views/pages/activitie/index.vue'], resolve),
        meta: {
          parent: "首页",
        },
        children: [
          {
            path: '/loveOrder',
            name: '关爱老人',
            component: resolve => require(['@/views/pages/activitie/loveOrder.vue'], resolve),
            meta: {
              parent: "公益活动",
            },
            children: [
              {
                path: '/activeText',
                name: '陵园新闻详情',
                component: resolve => require(['@/views/pages/activitie/activeText.vue'], resolve),
                meta: {
                  parent: "陵园新闻",
                },
              },
            ]
          },

        ]
      },
      {
        path: '/shopCar',
        name: '购物车',
        component: resolve => require(['@/views/pages/shopCar/shopCar.vue'], resolve),
        meta: {
          parent: "",
        },
        children: [
          {
            path: '/settlement',
            name: '确认订单',
            component: resolve => require(['@/views/pages/shopCar/settlement.vue'], resolve),
            meta: {
              parent: "购物车",
            },
          },
          {
            path: '/success',
            name: '支付完成',
            component: resolve => require(['@/views/pages/shopCar/success.vue'], resolve),
            meta: {
              parent: "购物车",
            },
          },
        ]
      },
      {
        path: '/login',
        name: '登录',
        component: resolve => require(['@/views/pages/Login/login.vue'], resolve),
        meta: {
          parent: "登录",
        },
      },
      {
        path: '/sacrifice',
        name: '在线祭祀',
        component: resolve => require(['@/views/pages/sacrifice/sacrifice.vue'], resolve),
        meta: {
          parent: "首页",
        },
      },
      {
        path: '/personalCenter',
        name: '个人中心',
        component: resolve => require(['@/views/pages/personalCenter/personalCenter.vue'], resolve),
        meta: {
          parent: "",
        },
        children: [
          {
            path: '/rechLogs',
            name: '积分记录',
            component: resolve => require(['@/views/pages/personalCenter/rechLogs.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/favorite',
            name: '查看收藏夹',
            component: resolve => require(['@/views/pages/personalCenter/favorite.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/quan',
            name: '优惠券',
            component: resolve => require(['@/views/pages/personalCenter/quan.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/gzShop',
            name: '关注店铺',
            component: resolve => require(['@/views/pages/personalCenter/gzShop.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/pointRecharge',
            name: '积分充值',
            component: resolve => require(['@/views/pages/personalCenter/pointRecharge.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/evaluate',
            name: '商品评价',
            component: resolve => require(['@/views/pages/personalCenter/evaluate.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/shopDetails',
            name: '商品详情',
            component: resolve => require(['@/views/pages/personalCenter/shopDetails.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/refund',
            name: '申请退款',
            component: resolve => require(['@/views/pages/personalCenter/refund.vue'], resolve),
            meta: {
              parent: "个人中心",
            },
          },
          {
            path: '/setting',
            name: '账号设置',
            component: resolve => require(['@/views/pages/personalCenter/setting.vue'], resolve),
            meta: {
              parent: "账号设置",
            },
          },
        ]
      },
      {
        path: '/CooperativeCemetery',
        name: '合作陵园',
        component: resolve => require(['@/views/pages/Cemetery/CooperativeCemetery.vue'], resolve),
        meta: {
          parent: "首页",
        },
        children: [
          {
            path: '/cemeteryList',
            name: '全部陵园',
            component: resolve => require(['@/views/pages/Cemetery/CooperativeCemetery.vue'], resolve),
            meta: {
              parent: "首页",
            },
          },
          {
            path: '/cemeteryDetail',
            name: '陵园首页',
            component: resolve => require(['@/views/pages/Cemetery/cemeteryDetail.vue'], resolve),
            meta: {
              parent: "合作陵园",
            },
            children: [
              {
                path: '/activeLy',
                name: '限时活动',
                component: resolve => require(['@/views/pages/Cemetery/Park/activeLy.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
              {
                path: '/ParkDetail',
                name: '园区详情',
                component: resolve => require(['@/views/pages/Cemetery/Park/ParkDetail.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
              {
                path: '/ParkList',
                name: '精选园区',
                component: resolve => require(['@/views/pages/Cemetery/Park/ParkList.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
              // ParkList
              {
                path: '/ValetMartyrs',
                name: '代客祭扫',
                component: resolve => require(['@/views/pages/Cemetery/Park/ValetMartyrs.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
              {
                path: '/FlowersBooking',
                name: '鲜花预定',
                component: resolve => require(['@/views/pages/Cemetery/Park/FlowersBooking.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
              {
                path: '/AshDeposite',
                name: '骨灰寄存',
                component: resolve => require(['@/views/pages/Cemetery/Park/AshDeposite.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
              {
                path: '/StoreOnline_ly',
                name: '在线商城',
                component: resolve => require(['@/views/pages/Cemetery/Park/StoreOnline_ly.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
                children: [
                  {
                    path: '/goodsList_ly',
                    name: '全部商品',
                    component: resolve => require(['@/views/pages/Cemetery/Park/StoreOnline_ly.vue'], resolve),
                    meta: {
                      parent: "陵园首页",
                    },
                  }
                ]
              },
              {
                path: '/EtiquetteService',
                name: '礼仪服务',
                component: resolve => require(['@/views/pages/Cemetery/Park/EtiquetteService.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
              {
                path: '/AfterSalesService',
                name: '售后服务',
                component: resolve => require(['@/views/pages/Cemetery/Park/AfterSalesService.vue'], resolve),
                meta: {
                  parent: "陵园首页",
                },
              },
            ]
          },
        ]
      },
      {
        path: '/StoreOnline_tr',
        name: '在线商城',
        component: resolve => require(['@/views/pages/StoreOnline/StoreOnline_tr.vue'], resolve),
        meta: {
          parent: "首页",
        },
        children: [
          {
            path: '/goodsList_tr',
            name: '全部商品',
            component: resolve => require(['@/views/pages/StoreOnline/StoreOnline_tr.vue'], resolve),
            meta: {
              parent: "首页",
            },
          },
          {
            path: '/couponActivities',
            name: '优惠券活动',
            component: resolve => require(['@/views/pages/StoreOnline/couponActivities.vue'], resolve),
            meta: {
              parent: "首页",
            },
          },
          {
            path: '/dzpActivities',
            name: '大转盘抽奖活动',
            component: resolve => require(['@/views/pages/StoreOnline/dzpActivities.vue'], resolve),
            meta: {
              parent: "限时活动",
            },
          },
          {
            path: '/sweepActivities',
            name: '抽奖活动',
            component: resolve => require(['@/views/pages/StoreOnline/sweepActivities.vue'], resolve),
            meta: {
              parent: "首页",
            },
          },
          {
            path: '/ShopDetail',
            name: '店铺详情',
            component: resolve => require(['@/views/pages/StoreOnline/ShopDetail.vue'], resolve),
            meta: {
              parent: "在线商城",
            },
            children: [
              {
                path: '/GoodsList',
                name: '商品分类',
                component: resolve => require(['@/views/pages/StoreOnline/GoodsList.vue'], resolve),
                meta: {
                  parent: "店铺详情",
                },
              },
              {
                path: '/GoodsDetail',
                name: '商品分类',
                component: resolve => require(['@/views/pages/StoreOnline/GoodsDetail.vue'], resolve),
                meta: {
                  parent: "店铺详情",
                },
              },
              {
                path: '/activeSh',
                name: '限时活动',
                component: resolve => require(['@/views/pages/StoreOnline/activeSh.vue'], resolve),
                meta: {
                  parent: "店铺详情",
                },
              },
              {
                path: '/discountList',
                name: '优惠券',
                component: resolve => require(['@/views/pages/StoreOnline/discountList.vue'], resolve),
                meta: {
                  parent: "店铺详情",
                },
              },
            ]
          }
        ]
      },
      {
        path: '/PointsMall',
        name: '积分商城',
        component: resolve => require(['@/views/pages/PointsMall/PointsMall.vue'], resolve),
        meta: {
          parent: "首页",
        },
      },
      {
        path: '/MapSeekTomb',
        name: '地图找墓',
        component: resolve => require(['@/views/pages/MapSeekTomb/MapSeekTomb.vue'], resolve),
        meta: {
          parent: "首页",
        },
      },

    ]
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from,savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }

})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

router.beforeEach((to, from, next) => {
  
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
  if (to.path == '/login') {
    //保存当前路由
      sessionStorage.setItem("preRoute", router.currentRoute.fullPath)
  }
  window.scrollTo(0, 0); //切换页面 滚动条恢复初始状态
  // if (to.path === '/login') {
    // window.open( '_blank')
    next()
    // if(to.path === '/personalCenter'){
    //   // window.open( '_blank')
    //   next({
    //       path: '/personalCenter',
    //       query: { redirect: to.fullPath }
    //   })
    // }else{

    // }
  // next()
  //   if (to.meta.requireAuth) { // 如果访问的页面必须要登录
  //     if (getToken()) {
  //         next(); /*  */
  //     } else {
  //         next({
  //             path: '/login',
  //             query: { redirect: to.fullPath }
  //         })
  //     }
  // } else {
  //     next();
  // }
  // } else {
  //   if (!window.sessionStorage.getItem('token')) {
  //     next('/login')
  //   } else {
  //     next()
  //   }
  // }
})


export default router
