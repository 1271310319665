export default {
  data() {
    return {
    }
  },
  methods: {
    /*
    * 路由跳转
    * 参数 url --路径
    * 参数 parameter -- 地址栏携带参数
    */
    routeToNew(url, parameter = {}) {
      if (typeof url === "string") {
        this.$router.push({ path: url, query: parameter });
        // const { href } = this.$router.resolve({
        //   path: url,
        //   query: parameter
        // })
        // window.open(href, '_blank')
      }
    },
    routeTo(url, parameter = {}) {
      if (typeof url === "string") {
        this.$router.push({ path: url, query: parameter });
      }
    },
    route(id) {
      // this.$router.push({ path: '/cemeteryDetail', query: { 'companyId': id } });
      const { href } = this.$router.resolve({
        path: '/cemeteryDetail',
        query: { 'companyId': id } 
      })
      
      sessionStorage.setItem('companyIdCe',id);
      window.open(href, '_blank')
    },
    menusShow(val) {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      return userInfo.menus.indexOf(val) != -1
    },
    goodsDetail(item) {
      console.log(item);
      sessionStorage.setItem("itemDetails", JSON.stringify(item));
      this.$router.push({ path: "/GoodsDetail", query: { 'itemDetails': JSON.stringify(item) } });
      // const { href } = this.$router.resolve({
      //   path: '/GoodsDetail',
      //   query: { 'itemDetails': JSON.stringify(item) } 
      // })
      // window.open(href, '_blank')
    },
      //百度地图获取城市名称的方法
        getCurrentCityName() {
          return new Promise(function (resolve, reject) {
              let myCity = new BMap.LocalCity()
              myCity.get(function (result) {
                  resolve(result.name)
              })
          })
        }
  }
}