/**
 * 对象是否为空或者空字符串
 * @param obj
 * @returns {boolean}
 */
export function isEmpty(obj) {
    return obj === null || obj === undefined || obj === '';
}
/**
 * 日期格式化 yyyy-MM-dd HH:mm:ss
 * @param date
 * @returns {*}
 */
export function formatDateTime(date, pattern) {
    if (!date) {
        return
    }
    switch (typeof date) {
        case 'string':
            date = new Date(date.replace(/-/, '/'))
            break
        case 'number':
            date = new Date(date)
            break
    }
    if (!(date instanceof Date)) {
        return
    }
    let dict = {
        'yyyy': date.getFullYear(),
        'M': date.getMonth() + 1,
        'd': date.getDate(),
        'H': date.getHours(),
        'm': date.getMinutes(),
        's': date.getSeconds(),
        'MM': ('' + (date.getMonth() + 101)).substr(1),
        'dd': ('' + (date.getDate() + 100)).substr(1),
        'HH': ('' + (date.getHours() + 100)).substr(1),
        'mm': ('' + (date.getMinutes() + 100)).substr(1),
        'ss': ('' + (date.getSeconds() + 100)).substr(1)
    }
    return pattern.replace(/(yyyy|MM?|dd?|HH?|ss?|mm?)/g, function () {
        return dict[arguments[0]]
    })
}
/**
 * 日期格式化 转换成时间戳
 * @param date
 * @returns {*}
 */
export function formatTimestamp(date) {
    let timestamp = new Date(Date.parse(date.replace(/-/g, "/")));
    timestamp = timestamp.getTime();
    return timestamp
}

export function formatDateTimeO(date){
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    return y + '-' + m + '-' + d+' '+h+':'+minute;
}
export function formatDateTimeT(date){
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
}

export function timestampToTime(timestamp) {
    var date = new Date(timestamp); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
    var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
    
    return Y+M+D+h+m+s;
}
const digitsRE = /(\d{3})(?=\d)/g
/**
 * 金额格式化
 * @param value 要格式化的数字
 * @param currency 货币前缀 默认""
 * @param  decimals 保留几位小数 默认2
 * @param  unit 单位 默认""
 * @returns {string}
 */
export function formatCurrency(value, decimals = 2, currency = "", unit = "") {
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return ''
    var stringified = Math.abs(value).toFixed(decimals)
    var _int = decimals ? stringified.slice(0, -1 - decimals) : stringified
    var i = _int.length % 3
    var head = i > 0 ? (_int.slice(0, i) + (_int.length > 3 ? ',' : '')) : ''
    var _float = decimals ? stringified.slice(-1 - decimals) : ''
    var sign = value < 0 ? '-' : ''
    return sign + currency + head + _int.slice(i).replace(digitsRE, '$1,') + _float + unit
}
// 删除数组的某个对象
export function removeByValue(obj, val) {
    for (var i = 0; i < obj.length; i++) {
        if (obj[i] === val) {
            obj.splice(i, 1)
            break
        }
    }
}
/**
 * 清除对象中非零的空值（包含NAN）
 * @param obj 返回源对象
 */
export function clearEmptyValue(obj) {
    Object.keys(obj).forEach(key => {
        let value = (obj[key])
        if (value !== 0 && !value) {
            delete obj[key]
        }
    })
    return obj
}

export function stringify(obj) {
    return JSON.stringify(obj, (key, value) => {
        if (value !== 0 && !value) {
            return undefined
        }
        return value
    })
}